import React from 'react';
import PropTypes from 'prop-types';
import { RelativePosition, Cloth, ClothText, Add, Icon, Quantity, AddRow } from './styledComponents';

const minusStyle = { marginRight: '8px' };
const nameLengthTreshold = 18;

const ClothComponent = ({ name, icon, quantity, onAdd, removeCloth }) => (
  <RelativePosition>
    <Cloth onClick={() => quantity === 0 && onAdd()} clickable={quantity === 0}>
      <Icon className={`flaticon-${icon}`} hideOnMobile={name.length > nameLengthTreshold} />
      <ClothText topMargin={name.length > nameLengthTreshold}>{name.replace('/', ' / ').toLocaleLowerCase()}</ClothText>
      <AddRow>
        {quantity > 0 &&
          <Add style={minusStyle} onClick={() => removeCloth()}>-</Add>
        }
        {quantity > 0 ?
          <Add onClick={() => onAdd()}>+</Add> :
          <Add>+</Add>
        }
      </AddRow>
    </Cloth>
    {quantity > 0 &&
      <Quantity quantity={quantity}>{quantity}</Quantity>
    }
  </RelativePosition>
);

ClothComponent.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  onAdd: PropTypes.func.isRequired,
  removeCloth: PropTypes.func.isRequired,
};

export default ClothComponent;
