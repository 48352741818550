import React from 'react';
import PropTypes from 'prop-types';
import { ClothesList } from './styledComponents';
import pricings from '../services/pricing';
import Cloth from './Cloth';
import assets from '../assets/assets';
import featureSwitch from '../services/featureSwitch';

// filters according to brands
function getFilteredPricings() {
  let filteredPricings = pricings;
  if (featureSwitch('clothesSelector_hideDeco')) {
    filteredPricings = filteredPricings.filter(cloth => cloth.slug !== 'deco');
  }
  if (featureSwitch('clothesSelector_hideMari')) {
    filteredPricings = filteredPricings.filter(cloth => cloth.slug !== 'mari');
  }
  return filteredPricings;
}

const ClothesSelector = ({ clothes, addCloth, removeCloth }) => (
  <ClothesList>
    {getFilteredPricings().map(cloth =>
      <Cloth
        key={cloth.slug}
        name={cloth.name}
        quantity={clothes.reduce((total, cloth2) => cloth2.slug === cloth.slug ? total + 1 : total, 0)}
        icon={assets[cloth.slug]}
        onAdd={() => addCloth({ slug: cloth.slug, name: cloth.name })}
        removeCloth={() => removeCloth(cloth.slug)}
      />,
    )}
  </ClothesList>
);

ClothesSelector.propTypes = {
  clothes: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string,
  })).isRequired,
  addCloth: PropTypes.func.isRequired,
  removeCloth: PropTypes.func.isRequired,
};

export default ClothesSelector;
