const iconsMapping = {
  mant: 'jacket-4',
  jupe: 'skirt',
  chem: 'shirt',
  haut: 'shirt',
  pant: 'jeans',
  robe: 'dress-1',
  ling: 'panties',
  pull: 'pullover',
  deco: 'theatre-curtains',
  div: '',
};

export default iconsMapping;
