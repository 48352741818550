import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';

import ClothesSelector from './ClothesSelector';
import LogoImg from '../assets/logo1-128.png';
import { trackEvent } from '../services/analytics';
import featureSwitch from '../services/featureSwitch';

import { Wrapper, Block, Header2, Title1bis, Logo, Highlight, Row, RowOnDesktop } from './styledComponents';
import { Button } from './Home/v2/StyledComponents';
import ProgressBar from './order/ProgressBar';

const Step1 = ({ clothes, addCloth, removeCloth, isNotValid, goNext, skip }) => (
  <Wrapper>
    <Block>
      <Header2>
        <Link
          to={'/'} style={{ flex: 1, textAlign: 'center' }}
          onClick={() => trackEvent('click', 'home_step1')}
        >
          <Logo src={LogoImg} alt="Retoucherie vêtement Marseille Paris Aix Metz dimanche" />
        </Link>
        <ProgressBar step={1} />
        <Title1bis>
          mes <Highlight>vêtements</Highlight>
        </Title1bis>
      </Header2>
      <ClothesSelector
        clothes={clothes}
        addCloth={addCloth}
        removeCloth={removeCloth}
      />
      {featureSwitch('step1_hideFastVariant') ?
        <Row center>
          <Button isNotValid={isNotValid} onClick={goNext}>{'suivant'}</Button>
        </Row>
        :
        <RowOnDesktop style={{ margin: '20px 0px' }}>
          <Button
            noMargin
            secondary
            isNotValid={isNotValid}
            onClick={goNext}
          >{'faire mon estimation'}</Button>
          <Button noMargin onClick={skip}>{'commander en 3 clics'}</Button>
        </RowOnDesktop>
      }
    </Block>
  </Wrapper>
);

Step1.propTypes = {
  goNext: PropTypes.func.isRequired,
  skip: PropTypes.func.isRequired,
  clothes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  addCloth: PropTypes.func.isRequired,
  removeCloth: PropTypes.func.isRequired,
  isNotValid: PropTypes.bool.isRequired,
};

export default Step1;
