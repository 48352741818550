import React from 'react';
import ClothesContainer from './containers/ClothesContainer';
import routes from '../../Routes';
import Layout from '../../layouts/index';

const fastVariantRoute = routes.EstimationVariantFabrics.url;
const estimationVariantRoute = routes.EstimationVariantAlterations.url;
const Step1Clothes = () => (
  <Layout slug="Step1">
    <ClothesContainer
      fastVariantRoute={fastVariantRoute}
      estimationVariantRoute={estimationVariantRoute}
    />
  </Layout>
);
export default Step1Clothes;
